import Image1 from "../../Assets/woman1.png";
import Image2 from "../../Assets/man.png";
import Image3 from "../../Assets/woman2.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Client A",
    description:
      "Manav's expertise in full-stack development significantly enhanced our project, delivering exceptional results.",
  },
  {
    id: 2,
    image: Image2,
    title: "Client B",
    description:
      "Manav's innovative approach and attention to detail brought fresh perspectives and improvements to our application.",
  },
  {
    id: 3,
    image: Image3,
    title: "Client C",
    description:
      "Manav quickly addressed issues and optimized the user experience, resulting in a more efficient and user-friendly product.",
  },
];
