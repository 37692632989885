import React, { useState } from "react";
import "./Services.css";
import {
  HiOutlineClipboardList,
  HiOutlineArrowSmRight,
  HiOutlineCheckCircle,
  HiX,
} from "react-icons/hi";

const Services = () => {
  const [activeModal, setActiveModal] = useState(null);

  const toggleTab = (service) => {
    setActiveModal(service === activeModal ? null : service);
  };

  return (
    <section className='services section' id='services'>
      <h2 className='section__title'>Services</h2>
      <span className='section__subtitle'>Expertise & Innovation</span>

      <div className='services__container container grid'>
        <div className='services__content'>
          <div>
            <HiOutlineClipboardList className='services__icon' />
            <h3 className='services__title'>
              Full Stack
              <br />
              Development
            </h3>
          </div>
          <span
            className='services__button'
            onClick={() => toggleTab("fullstack")}
            aria-expanded={activeModal === "fullstack"}
          >
            View More
            <HiOutlineArrowSmRight className='services__button-icon' />
          </span>
          <div
            className={
              activeModal === "fullstack"
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className='services__modal-content'>
              <HiX
                onClick={() => toggleTab(null)}
                className='services__modal-close'
              />
              <h3 className='services__modal-title'>Full Stack Development</h3>
              <p className='services__modal-description'>
                Leveraging over 3 years of experience in MERN stack development
                to build robust, user-centric applications.
              </p>
              <ul className='services__modal-services grid'>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />
                  <p className='services__modal-info'>
                    Web & app development with React.js and Node.js
                  </p>
                </li>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />
                  <p className='services__modal-info'>
                    Real-time collaboration features with WebSockets
                  </p>
                </li>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />
                  <p className='services__modal-info'>
                    Secure payment integration with RazorPay
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='services__content'>
          <div>
            <HiOutlineClipboardList className='services__icon' />
            <h3 className='services__title'>
              Freelance
              <br />
              Development
            </h3>
          </div>
          <span
            className='services__button'
            onClick={() => toggleTab("freelance")}
            aria-expanded={activeModal === "freelance"}
          >
            View More
            <HiOutlineArrowSmRight className='services__button-icon' />
          </span>
          <div
            className={
              activeModal === "freelance"
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className='services__modal-content'>
              <HiX
                onClick={() => toggleTab(null)}
                className='services__modal-close'
              />
              <h3 className='services__modal-title'>Freelance Development</h3>
              <p className='services__modal-description'>
                Delivering tailored web solutions with a focus on client
                satisfaction and responsive design.
              </p>
              <ul className='services__modal-services grid'>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />
                  <p className='services__modal-info'>
                    Customized website and landing page design
                  </p>
                </li>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />
                  <p className='services__modal-info'>
                    AWS-based backend infrastructure setup
                  </p>
                </li>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />
                  <p className='services__modal-info'>
                    Responsive design for optimal performance across devices
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='services__content'>
          <div>
            <HiOutlineClipboardList className='services__icon' />
            <h3 className='services__title'>
              Research &
              <br />
              Publications
            </h3>
          </div>
          <span
            className='services__button'
            onClick={() => toggleTab("research")}
            aria-expanded={activeModal === "research"}
          >
            View More
            <HiOutlineArrowSmRight className='services__button-icon' />
          </span>
          <div
            className={
              activeModal === "research"
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className='services__modal-content'>
              <HiX
                onClick={() => toggleTab(null)}
                className='services__modal-close'
              />
              <h3 className='services__modal-title'>Research & Publications</h3>
              <p className='services__modal-description'>
                Contributing to academic and industry knowledge through
                published research and innovative solutions.
              </p>
              <ul className='services__modal-services grid'>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />
                  <p className='services__modal-info'>
                    Research on sustainable materials and software engineering
                  </p>
                </li>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />
                  <p className='services__modal-info'>
                    Innovations in note-taking applications with MERN stack
                  </p>
                </li>
                <li className='services__modal-service'>
                  <HiOutlineCheckCircle className='services__modal-icon' />

                  <p className='services__modal-info'>
                    Published papers in reputable journals
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
