// import React from "react";
// import { FiTwitter, FiGithub, FiLinkedin } from "react-icons/fi";

// const Social = () => {
//   return (
//     <div className='home__social'>
//       <a
//         href='https://x.com/The_Higher_Man'
//         className='home__social-icon'
//         target='_blank'
//       >
//         <FiTwitter />
//       </a>
//       <a
//         href='https://github.com/ManavKumar1'
//         className='home__social-icon'
//         target='_blank'
//       >
//         <FiGithub />
//       </a>
//       <a
//         href='https://www.linkedin.com/in/manav-kumar-0'
//         className='home__social-icon'
//         target='_blank'
//       >
//         <FiLinkedin />
//       </a>
//     </div>
//   );
// };

// export default Social;
import React from "react";
import { FiTwitter, FiGithub, FiLinkedin } from "react-icons/fi";

// Inline styles
const styles = {
  socialContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  socialIcon: {
    fontSize: "1.25rem",
    color: "var(--title-color)",
    textDecoration: "none",
  },
  socialIconHover: {
    color: "var(--title-color-dark)",
  },
};

const Social = () => {
  return (
    <div style={styles.socialContainer}>
      <a
        href='https://x.com/The_Higher_Man'
        style={styles.socialIcon}
        onMouseEnter={(e) =>
          (e.currentTarget.style.color = styles.socialIconHover.color)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.color = styles.socialIcon.color)
        }
        target='_blank'
        rel='noopener noreferrer'
      >
        <FiTwitter />
      </a>
      <a
        href='https://github.com/ManavKumar1'
        style={styles.socialIcon}
        onMouseEnter={(e) =>
          (e.currentTarget.style.color = styles.socialIconHover.color)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.color = styles.socialIcon.color)
        }
        target='_blank'
        rel='noopener noreferrer'
      >
        <FiGithub />
      </a>
      <a
        href='https://www.linkedin.com/in/manav-kumar-0'
        style={styles.socialIcon}
        onMouseEnter={(e) =>
          (e.currentTarget.style.color = styles.socialIconHover.color)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.color = styles.socialIcon.color)
        }
        target='_blank'
        rel='noopener noreferrer'
      >
        <FiLinkedin />
      </a>
    </div>
  );
};

export default Social;
