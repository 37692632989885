import React, { useState } from "react";
import "./Qualification.css";
import {
  HiOutlineAcademicCap,
  HiOutlineBriefcase,
  HiOutlineCalendar,
} from "react-icons/hi";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className='qualification section'>
      <h2 className='section__title'>Qualification</h2>
      <span className='section__subtitle'>My Journey</span>

      <div className='qualification__container container'>
        <div className='qualification__tabs'>
          <div
            className={
              toggleState === 1
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <HiOutlineAcademicCap className='qualification__icon' />
            Education
          </div>
          <div
            className={
              toggleState === 2
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <HiOutlineBriefcase className='qualification__icon' />
            Experience
          </div>
        </div>

        <div className='qualification__sections'>
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            {/* Existing qualifications */}
            <div className='qualification__data'>
              <div>
                <h3 className='qualification__title'>Schooling</h3>
                <span className='qualification__subtitle'>High-School</span>
                <div className='qualification__calendar'>
                  <HiOutlineCalendar className='qualification__calendar-icon' />
                  2020
                </div>
              </div>
              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            <div className='qualification__data'>
              <div></div>
              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
              <div>
                <h3 className='qualification__title'>Bachelor of Technology</h3>
                <span className='qualification__subtitle'>
                  Poornima Institute of Engineering & Technology
                </span>
                <div className='qualification__calendar'>
                  <HiOutlineCalendar className='qualification__calendar-icon' />
                  March 2020 - 2024
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            {/* Experience section remains unchanged */}
            <div className='qualification__data'>
              <div>
                <h3 className='qualification__title'>
                  Freelance Full Stack Developer
                </h3>
                <span className='qualification__subtitle'>Self-employed</span>
                <div className='qualification__calendar'>
                  <HiOutlineCalendar className='qualification__calendar-icon' />
                  2022 -2024
                </div>
              </div>
              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            <div className='qualification__data'>
              <div></div>
              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
              <div>
                <h3 className='qualification__title'>MERN Stack Developer</h3>
                <span className='qualification__subtitle'>Compitcom</span>
                <div className='qualification__calendar'>
                  <HiOutlineCalendar className='qualification__calendar-icon' />
                  2024 - Present
                </div>
              </div>
            </div>
            <div className='qualification__data'>
              <div>
                <h3 className='qualification__title'>Full Stack Developer</h3>
                <span className='qualification__subtitle'>Freecharge</span>
                <div className='qualification__calendar'>
                  <HiOutlineCalendar className='qualification__calendar-icon' />
                  2024 - Present
                </div>
              </div>
              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
