import "./App.css";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Skills from "./Components/Skills/Skills";
import Services from "./Components/Services/Services";
import Qualification from "./Components/Qualification/Qualification";
import Testimonials from "./Components/Testimonials/Testimonials";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import ScrollUp from "./Components/Scrollup/ScrollUp";
import Portfolio from "./Components/Projects/Portfolio";

const App = () => {
  return (
    <>
      <Header />
      <main className='main'>
        <Home />
        <About />
        <Skills />
        <Services />
        <Qualification />
        <Portfolio />
        <Testimonials />
        <Contact />
      </main>
      <Footer />
      <ScrollUp />
    </>
  );
};

export default App;

// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import "./App.css";
// import Header from "./Components/Header/Header";
// import Home from "./Components/Home/Home";
// import About from "./Components/About/About";
// import Skills from "./Components/Skills/Skills";
// import Services from "./Components/Services/Services";
// import Qualification from "./Components/Qualification/Qualification";
// import Testimonials from "./Components/Testimonials/Testimonials";
// import Contact from "./Components/Contact/Contact";
// import Footer from "./Components/Footer/Footer";
// import ScrollUp from "./Components/Scrollup/ScrollUp";
// import Portfolio from "./Components/Projects/Portfolio";

// const App = () => {
//   return (
//     <Router>
//       <Header />
//       <main className='main'>
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/skills" element={<Skills />} />
//           <Route path="/services" element={<Services />} />
//           <Route path="/qualification" element={<Qualification />} />
//           <Route path="/portfolio" element={<Portfolio />} />
//           <Route path="/testimonials" element={<Testimonials />} />
//           <Route path="/contact" element={<Contact />} />
//         </Routes>
//       </main>
//       <Footer />
//       <ScrollUp />
//     </Router>
//   );
// };

// export default App;
