import React from "react";
import { HiCheckBadge } from "react-icons/hi2";

const Other = () => {
  return (
    <div className='skills__content'>
      <h3 className='skills__title'>Other Skills</h3>
      <div className='skills__box'>
        <div className='skills__group'>
          <div className='skills__data'>
            <HiCheckBadge />
            <div>
              <h3 className='skills__name'>React Native</h3>
              <span className='skills__level'>Intermediate</span>
            </div>
          </div>
          <div className='skills__data'>
            <HiCheckBadge />
            <div>
              <h3 className='skills__name'>TypeScript</h3>
              <span className='skills__level'>Intermediate</span>
            </div>
          </div>
        </div>
        <div className='skills__group'>
          <div className='skills__data'>
            <HiCheckBadge />
            <div>
              <h3 className='skills__name'>GraphQL</h3>
              <span className='skills__level'>Intermediate</span>
            </div>
          </div>
          <div className='skills__data'>
            <HiCheckBadge />
            <div>
              <h3 className='skills__name'>Firebase</h3>
              <span className='skills__level'>Intermediate</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Other;
